<script lang="ts" setup>
  const props = defineProps<{
    environment: keyof typeof environments
  }>()

  const route = useRoute()

  const environments = {
    portal: { color: 'text-primary', title: '' },
    management: { color: 'text-affirmative', title: 'Management' },
    academy: { color: 'text-critical', title: 'Academy' },
    cms: { color: 'text-black', title: 'CMS' },
  }

  const selectedEnvironment = computed(() => environments[props.environment])
</script>

<template>
  <header class="h-20 px-12 border-b border-[#e3e3e3] flex items-center shrink-0 bg-white">
    <div id="logo" class="flex-shrink-0">
      <NuxtLink to="/">
        <img alt="logo" src="/icons/Logo.svg" />
      </NuxtLink>
    </div>
    <div id="env" class="px-12 w-full">
      <h3 :class="selectedEnvironment.color" class="text-2xl font-bold">
        {{ selectedEnvironment.title }}
      </h3>
    </div>
    <div class="flex flex-row justify-end w-full mr-4 gap-2">
      <JiraFeedback />
    </div>
    <div class="ml-auto flex-shrink-0">
      <TheProfileMenu id="profile-menu" :environment="environment" />
    </div>
  </header>
</template>

<style scoped></style>
