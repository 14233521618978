<script setup lang="ts">
  import { AuthRole } from '~/utils/auth'

  const open = ref(false)
  const props = defineProps<{
    environment: 'portal' | 'management'
  }>()

  const subMenuOpen = ref(false)
  const { locale, setLocale } = useI18n()

  const toggleMenu = () => {
    if (open.value) {
      open.value = false
      subMenuOpen.value = false
    } else {
      open.value = true
    }
  }

  const profileMenu = ref<HTMLElement | null>(null)

  onClickOutside(profileMenu, (evt) => {
    if (open.value) {
      open.value = false
      subMenuOpen.value = false
    }
  })

  const authStore = useAuthStore()

  const initials = computed(() => {
    return authStore?.name
      .split(' ')
      .map((n: string) => n[0])
      .join('')
      .replace(/[^a-zA-Z]/g, '')
      .toUpperCase()
  })

  const buttonUrl = computed(() =>
    props.environment === 'portal' ? '/hub/dashboard' : '/portal/dashboard',
  )
</script>

<template>
  <div class="relative" ref="profileMenu">
    <button
      type="button"
      class="cursor-pointer flex flex-row items-center relative rounded-full focus:ring-4 focus:outline-none focus:ring-focused transition-all duration-250"
      @click.stop="toggleMenu"
    >
      <span
        class="bg-primary w-8 h-8 text-center font-bold mr-2 text-sm text-white rounded-full justify-center flex items-center"
        >{{ initials }}
      </span>
      <span class="flex flex-col text-sm mr-6">
        <span class="text-left text-primary font-bold leading-3">
          {{ authStore?.name }}
        </span>
        <!-- <span class="text-left text-gray-400">GMP+ International</span> -->
      </span>
      <img
        src="/icons/chevron-down-primary.svg"
        alt=""
        class="w-3 h-3 transform transition-all duration-300"
        :class="{
          'rotate-180': open,
          'rotate-0': !open,
        }"
      />
    </button>

    <div
      v-show="open && !subMenuOpen"
      class="absolute mt-4 border right-0 flex flex-col min-w-[200px] divide-y rounded-lg bg-white transition-all"
    >
      <AuthGuard :roles="[AuthRole.QRAY_GMP]">
        <NuxtLink :to="buttonUrl">
          <ProfileMenuItem
            :label="`nav.profile.switch.${props.environment === 'portal' ? 'management' : 'portal'}`"
            icon="env-24px-black"
          />
        </NuxtLink>
      </AuthGuard>

      <ProfileMenuItem
        label="nav.profile.language.title"
        icon="language-24px-black"
        @click="subMenuOpen = !subMenuOpen"
      >
        <img src="/icons/chevron-right-24px-black.svg" alt="" class="w-6 h-6 ml-auto" />
      </ProfileMenuItem>
      <!-- <AuthGuard :roles="[AuthRole.QRAY_GMP]">
        <ProfileMenuItem label="nav.profile.settings" icon="settings-outline-black" />
      </AuthGuard> -->
      <ProfileMenuItem
        label="nav.profile.logout"
        icon="logoff-24px-black"
        @click="authStore.logout()"
      />
    </div>
    <div
      v-if="subMenuOpen"
      class="absolute mt-4 border right-0 flex flex-col w-max min-w-[200px] divide-y rounded-lg bg-white"
    >
      <ProfileMenuItem label="nav.profile.language.title" @click="subMenuOpen = false">
        <template #prefix>
          <img src="/icons/chevron-right-24px-black.svg" alt="" class="w-6 h-6 mr-2 rotate-180" />
        </template>
      </ProfileMenuItem>
      <ProfileMenuItem
        radio
        label="nav.profile.language.en"
        @click="setLocale('en')"
        :active="locale == 'en'"
      >
        <template #prefix>
          <img src="/flags/en.svg" alt="" class="w-6 h-6 mr-2" />
        </template>
      </ProfileMenuItem>
      <ProfileMenuItem
        radio
        label="nav.profile.language.nl"
        @click="setLocale('nl')"
        :active="locale == 'nl'"
      >
        <template #prefix>
          <img src="/flags/nl.svg" alt="" class="w-6 h-6 mr-2" />
        </template>
      </ProfileMenuItem>
      <ProfileMenuItem
        radio
        label="nav.profile.language.de"
        @click="setLocale('de')"
        :active="locale == 'de'"
      >
        <template #prefix>
          <img src="/flags/de.svg" alt="" class="w-6 h-6 mr-2" />
        </template>
      </ProfileMenuItem>
    </div>
  </div>
</template>

<style scoped></style>
